// ------------------------------------------
//  Media - images, audio, video
// ------------------------------------------
.map {
    // display: none;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    // visibility: hidden;

    .is-visible & {
        // display: block;
        // visibility: visible;
    }
}

.gallery {
    display: none;
}

.img--resposive {
    width: 100%;
    height: auto;
}

// Lightbox
.lb-outerContainer,
.lightbox .lb-image {
    border-radius: 0;
}

.lb-dataContainer {
    position: fixed;
    top: $box-margin;
    right: $box-margin;
}

.lb-data .lb-close {
    display: block;
    padding: 10px 20px;
    background-position: center;
}

.lightboxOverlay {
    opacity: .9;
}
