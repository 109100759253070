// ------------------------------------------
//  Mixins
// ------------------------------------------

@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-align() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


// ------------------------------------------
//  Grid mixins
// ------------------------------------------

@each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);

    @include grid-media-query($name) {
        .#{$name}--show { display: inline-block; }
    }
}

@each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);

    @include grid-media-query($name) {
        .#{$name}--hide { display: none; }
    }
}
