// ------------------------------------------
//  Fonts
// ------------------------------------------
%font--1 {
    font-family: 'Cormorant SC', serif;
}

%font--2 {
    font-family: 'Cormorant', serif;
    font-weight: 300;
}

%font--3 {
    font-family: 'Open Sans', sans-serif;
}
