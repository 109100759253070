// ------------------------------------------
//  Cookies
// ------------------------------------------
.cookies-info {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 120px;
    padding: 15px;
    background-color: rgba($color-black, .8);
    color: rgba($color-white, .7);
    text-align: center;
    z-index: 100;
}

.cookies-box {
    a {
        color: $color-white;
        text-decoration: none;

    }

    p {
        margin: 0 0 15px;
        padding: 0;
    }
}
