// ------------------------------------------
//  Header
// ------------------------------------------

.header__socials {
    position: absolute;
    top: 40px;
    left: 30px;
    font-size: 24px;
    z-index: 3;
}
