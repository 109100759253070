// ------------------------------------------
//  Animation
// ------------------------------------------

@keyframes hamburger-moves {
    30% {
        left: -6px;
    }

    60% {
        left: 3px;
    }

    100% {
        left: 0;
    }
}
