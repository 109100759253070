@font-face {
    font-family: 'taxi';
    src: url('../fonts/taxi.eot?6012074');
    src: url('../fonts/taxi.eot?6012074#iefix') format('embedded-opentype'),
       url('../fonts/taxi.woff?6012074') format('woff'),
       url('../fonts/taxi.ttf?6012074') format('truetype'),
       url('../fonts/taxi.svg?6012074#taxi') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'taxi';
    src: url('../font/taxi.svg?6012074#taxi') format('svg');
  }
}
*/

 [class^='icon-']::before,
 [class*=' icon-']::before {
    font-family: 'taxi';
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-facebook::before { content: '\e800'; } /* '' */
.icon-gplus::before { content: '\e801'; } /* '' */
.icon-mail::before { content: '\e802'; } /* '' */
.icon-youtube::before { content: '\e803'; } /* '' */
.icon-phone::before { content: '\e942'; }
.icon-address-book::before {
    content: '\e944';
}
