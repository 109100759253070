// ------------------------------------------
//  Typography - paragraphs
// ------------------------------------------

.text--normal {
    margin-bottom: 0;
    letter-spacing: .035em;
    line-height: 1.6em;

    @include min-screen($xl) {
        line-height: 1.8em;
        letter-spacing: .04em;
    }
}
