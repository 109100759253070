/* Preload images */
body {
    &::after {
        display: none;
    }

    &.lb-disable-scrolling {
        overflow: hidden;
    }
}

.lightboxOverlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    background-color: $color-black;
    opacity: .8;
    z-index: 9999;
}

.lightbox {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 20px;
    font-weight: normal;
    line-height: 0;
    text-align: center;
    z-index: 10000;

    @include min-screen($md) {
        margin-top: 40px;
    }

    .lb-image {
        display: block;
        max-width: inherit;
        height: auto;
        border-radius: 3px;
    }

    a img {
        border: 0;
    }
}

.lb-outerContainer {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: $color-white;
    zoom: 1;

    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

.lb-container {
    padding: 4px;
}

.lb-loader {
    position: absolute;
    top: 43%;
    left: 0;
    width: 100%;
    height: 25%;
    line-height: 0;
    text-align: center;
}

.lb-cancel {
    display: block;
    width: 32px;
    height: 32px;
    margin: 0 auto;
}

.lb-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.lb-container > .nav {
    left: 0;
}

.lb-nav a {
    outline: none;
    background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev,
.lb-next {
    display: block;
    height: 100%;
    cursor: pointer;
}

.lb-next {
    position: relative;
    right: 0;

    @include min-screen($xl) {
        right: -60px;
    }

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 15px);
        right: 30px;
        width: 30px;
        height: 30px;
        border-top: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(45deg);
        box-shadow: 2px -2px 2px 0 rgba(black, .4);

        @include min-screen($xl) {
            right: 10px;
        }
    }
}

.lb-prev {
    position: relative;
    left: 0;

    @include min-screen($xl) {
        left: -60px;
    }

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 15px);
        left: 30px;
        width: 30px;
        height: 30px;
        border-top: 2px solid white;
        border-left: 2px solid white;
        transform: rotate(-45deg);
        box-shadow: -2px -2px 2px 0 rgba(black, .4);

        @include min-screen($xl) {
            left: 10px;
        }
    }
}

.lb-nav a.lb-prev {
    width: 34%;
    float: left;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-nav a.lb-next {
    width: 64%;
    float: right;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.lb-dataContainer {
    margin: 0 auto;
    padding-top: 5px;
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    zoom: 1;
}

.lb-dataContainer:after {
    content: '';
    display: table;
    clear: both;
}

.lb-data {
    padding: 0 4px;
    color: #ccc;
}

.lb-data .lb-details {
    width: 85%;
    float: left;
    text-align: left;
    line-height: 1.1em;
}

.lb-data .lb-caption {
    font-size: 0;
    font-weight: bold;
    line-height: 1em;
}

.lb-data .lb-number {
    display: block;
    padding-bottom: 1em;
    clear: left;
    color: #999999;
    font-size: 12px;
}

.lb-data .lb-close {
    display: block;
    width: 30px;
    height: 30px;
    float: right;
    transition: opacity .2s;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
    outline: none;
    text-align: right;
    opacity: .7;
}

.lb-data .lb-close:hover {
    cursor: pointer;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}
