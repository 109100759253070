// ------------------------------------------
//  Buttons
// ------------------------------------------

.btn-container {
    display: block;
}

.btn {
    @extend %font--3;
    display: inline-block;
    padding: 10px 15px;
    transition: all $animation-time ease-in-out;
    border: 0;
    border-radius: 0;
    outline: 0;
    background: $color-transparent;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .1em;
    text-decoration: none;
    text-transform: uppercase;
}

.btn--light {
    border: 1px solid $color-white;
    background-color: rgba($color-black, .4);
    color: $color-white;

    &:hover {
        @include min-screen($lg) {
            background: $color-white;
            color: $color-black;
        }
    }
}

.btn--dark {
    border: 1px solid $color-black;
    background-color: rgba($color-white, .4);
    color: $color-black;

    &:hover {
        @include min-screen($lg) {
            background: $color-black;
            color: $color-white;
        }
    }
}

.btn--big {
    padding: 14px 20px;
}

.btn--top-space {
    margin-top: 30px;
}

.lb-close,
.btn--close {
    cursor: pointer;

    &::after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        width: 40px;
        height: 2px;
        margin: 35px 20px;
        transform: translateZ(0) rotate(45deg);
        transition: all .4s;
        background: $color-white;

        @include min-screen($md) {
            margin: 55px 40px;
        }
    }

    &::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        width: 40px;
        height: 2px;
        margin: 35px 20px;
        transform: translateZ(0) rotate(-45deg);
        transition: all .4s;
        background: $color-white;

        @include min-screen($md) {
            position: fixed;
            margin: 55px 40px;
        }
    }
}

.btn--close {
    position: fixed;
    top: 0;
    right: 0;
    padding: 30px;
    cursor: pointer;
    z-index: 5;

    @include min-screen($md) {
        display: none;
    }
}
