// ------------------------------------------
//  Links
// ------------------------------------------

.link--normal {
    color: $color-black;
    font-weight: 400;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.link--social {
    margin: 5px 10px;
    padding: 5px 0;
    transition: all $animation-time ease-in-out;
    color: rgba($color-white, .8);
    text-decoration: none;
    text-shadow: 1px 1px rgba($color-black, .5);

    @include min-screen($lg) {
        margin: 0;
        padding: 10px 5px;
    }

    &:hover,
    &:visited,
    &:focus {
        color: $color-white;
        text-decoration: none;
    }

    &:hover {
        color: $color-white;
    }
}

.link--social--black {
    color: rgba($color-black, .6);
    text-shadow: none;

    &:hover,
    &:visited,
    &:focus {
        color: $color-black;
    }
}

.link--social--big {
    padding: 30px;
    font-size: 36px;

    @include min-screen($sm) {
        font-size: 42px;
    }

    @include min-screen($md) {
        font-size: 36px;
    }

    @include min-screen($lg) {
        font-size: 42px;
    }

    @include min-screen($xl) {
        font-size: 52px;
    }
}

.link--invisible {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;

    @include min-screen($lg) {
        display: block;
    }
}
