// ------------------------------------------
//  Navigations
// ------------------------------------------
.nav--main {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 100%;
    left: 0;
    width: 100%;
    transform: translate3d(0, 0, 0);
    transition: $animation-time all $animation-time ease-in-out;
    font-size: 18px;
    z-index: 3;

    &::after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: $box-margin;
        background: $color-white;
        z-index: 20;
    }

    .navigation--is-open & {
        bottom: 0;
        transition: none;
    }
}

.navigation--is-open {
    overflow: hidden;
}


// ------------------------------------------
//  Hamburger
// ------------------------------------------
$hamburger--height: 50px;
$hamburger--width: 40px;
$hamburger--space: 10px;
$hamburger--weight: 2px;
$hamburger--center: (($hamburger--height - $hamburger--space + 3 * $hamburger--weight) / 2);
$hamburder--margin: 40px;
$hamburger--color: #ffffff;

.nav__trigger {
    display: block;
    position: fixed;
    top: ($hamburder--margin - $hamburger--center / 2);
    right: $hamburder--margin;
    width: $hamburger--width;
    height: $hamburger--height;
    transition: all $animation-time ease-in-out;
    cursor: pointer;
    z-index: 2;

    &:hover {
        span::after,
        span::before {
            animation: hamburger-moves .6s 0s 1;
            animation-timing-function: ease-out;
        }
    }

    span {
        display: block;
        position: absolute;
        margin-top: $hamburger--center;

        &,
        &::before,
        &::after {
            width: 100%;
            height: $hamburger--weight;
            transform: translate3d(0, 0, 0);
            transition: all .4s;
            background: $hamburger--color;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
        }

        &::after {
            margin-top: -$hamburger--space;
        }

        &::before {
            margin-top: $hamburger--space;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -10px;
        bottom: 0;
        left: -10px;
        transition: background-color .5s ease-in-out;
        background-color: rgba($color-black, .0);
        z-index: -1;
    }

    .scrolled & {
        &::after {
            background-color: rgba($color-black, .2);
        }
    }
}

.nav__toggle {
    display: none;
}

.nav--container {
    display: block;
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: $box-margin;
    padding: 0 0 70px;
    transition: all $animation-time ease-in-out;
    list-style: none;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    visibility: hidden;
    z-index: 3;

    @include min-screen($lg) {
        top: 50%;
        margin: 0;
        padding: 0;
        transform: translateY(-50%);
    }

    > li {
        text-align: center;
        opacity: 0;

        &:nth-child(1) { transition: 0s opacity $animation-time ease-in-out; }
        &:nth-child(2) { transition: 0s opacity $animation-time ease-in-out; }
        &:nth-child(3) { transition: 0s opacity $animation-time ease-in-out; }
        &:nth-child(4) { transition: 0s opacity $animation-time ease-in-out; }
        &:nth-child(5) { transition: 0s opacity $animation-time ease-in-out; }

        a {
            @extend %font--2;
            display: inline-block;
            position: relative;
            padding: 0 0 10px;
            color: $color-white;
            font-size: 38px;
            text-align: center;
            text-decoration: none;
            white-space: nowrap;

            @include min-screen($md) {
                font-size: 46px;
            }

            @include min-screen($lg) {
                transition: color $animation-time ease-in-out;
                color: rgba($color-white, .9);
                font-size: 48px;

                &:hover {
                    color: $color-white;

                    &::after {
                        right: 0;
                        left: 0;
                    }
                }
            }

            @include min-screen($xl) {
                font-size: 52px;
            }

            @include min-screen($xxl) {
                font-size: 62px;
            }

            &::after {
                content: '';
                position: absolute;
                right: 52%;
                bottom: 5px;
                left: 52%;
                height: 1px;
                transition: all $animation-time ease-in-out;
                background-color: $color-white;
            }
        }
    }

    + .nav__trigger::before {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: $box-margin;
        transition: background $animation-time ease-in-out;
        background-color: $color-transparent;
        pointer-events: none;
    }
}

.nav__toggle:checked + .nav--container {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    > li {
        opacity: 1;

        &:nth-child(1) { transition: $animation-time opacity $animation-time ease-in-out; }
        &:nth-child(2) { transition: 2 * $animation-time opacity $animation-time ease-in-out; }
        &:nth-child(3) { transition: 3 * $animation-time opacity $animation-time ease-in-out; }
        &:nth-child(4) { transition: 4 * $animation-time opacity $animation-time ease-in-out; }
        &:nth-child(5) { transition: 5 * $animation-time opacity $animation-time ease-in-out; }
    }

    + .nav__trigger {
        span {
            background-color: $color-transparent;

            &::before {
                margin: 0;
                transform: rotate(225deg);
            }

            &::after {
                margin: 0;
                transform: rotate(-225deg);
            }
        }

        &::after {
            background-color: rgba($color-black, .75);
        }

        &::before {
            background-color: rgba($color-black, .9);
        }
    }
}
