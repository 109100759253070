html.remodal-is-locked {
    overflow: hidden;
    touch-action: none;
}

.remodal,
[data-remodal-id] {
  display: none;
}

/* Necessary styles of the overlay */

.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;

  display: none;
}

/* Necessary styles of the wrapper */

.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;
  overflow: auto;

  text-align: center;

  -webkit-overflow-scrolling: touch;
}

.remodal-wrapper:after {
  display: inline-block;

  height: 100%;
  margin-left: -0.05em;

  content: "";
}

/* Fix iPad, iPhone glitches */

.remodal-overlay,
.remodal-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Necessary styles of the modal dialog */

.remodal {
    position: relative;
    outline: none;
    text-size-adjust: 100%;
}

.remodal-is-initialized {
    display: inline-block;
}


.remodal {
    width: calc(100% - 40px);
    max-width: 600px;
    margin: 50px auto;
    color: $color-white;
    font-weight: 500;
    cursor: auto;

    @include min-screen($md) {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 85%;
        margin: auto;
        transform: translate(-50%, -50%);
    }
}

.remodal-overlay {
    display: block !important;
    transition: all $animation-time ease-in-out;
    background-color: $color-transparent;
    color: $color-white;
    pointer-events: none;

    &.remodal-is-opened {
        background-color: rgba($color-black, .9);

        &::after,
        &::before {
            transition: background $animation-time ease-in-out;
            background: $color-white;
        }
    }

    &::after {
        content: '';
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        width: 40px;
        height: 2px;
        margin: 35px 20px;
        transform: translateZ(0) rotate(45deg);
        transition: all .4s;
        background: $color-transparent;

        @include min-screen($md) {
            display: block;
            margin: 55px 40px;
        }
    }

    &::before {
        content: '';
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        width: 40px;
        height: 2px;
        margin: 35px 20px;
        transform: translateZ(0) rotate(-45deg);
        transition: all .4s;
        background: $color-transparent;

        @include min-screen($md) {
            display: block;
            position: fixed;
            margin: 55px 40px;
        }
    }
}

.remodal-wrapper {
    transition: $animation-time opacity $animation-time ease-in-out;
    cursor: pointer;
    opacity: 0;

    &::after {
        display: none;
    }

    &.remodal-is-opened {
        opacity: 1;
    }
}
