// ------------------------------------------
//  Colors
// ------------------------------------------
$color-black: #000000;
$color-white: #ffffff;
$color-transparent: rgba(0, 0, 0, 0);

// Navigation:
$nav-bg: rgba($color-black, .9);
$nav-link: #ffffff;


// ------------------------------------------
//  Animations
// ------------------------------------------
$animation-time: .4s;


// ------------------------------------------
//  Dimensions
// ------------------------------------------
$box-margin: $gutter;
