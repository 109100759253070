// ------------------------------------------
//  Contact
// ------------------------------------------
.contact-box {
    max-width: 300px;
    margin: 40px auto 50px;

    @include min-screen($xxl) {
        margin: 60px auto 80px;
    }
}

.contact-box__item {
    display: block;
    position: relative;
    padding-left: 40px;
    line-height: 1em;
    text-align: left;

    & + & {
        margin-top: 30px;
    }

    i {
        position: absolute;
        top: 50%;
        margin-top: 1px;
        margin-left: -40px;
        transform: translateY(-50%);
    }
}

.contact__intro {
    @include min-screen($md) {
        display: none;
    }

    @include min-screen($lg) {
        display: block;
    }
}
