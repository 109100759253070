// ------------------------------------------
//  Header
// ------------------------------------------
$bg-1: '../images/photo6--md.jpg';

.parallax-banner {
    display: block;
    position: relative;
    max-width: 100%;
    height: calc(100vh - 2 * #{$box-margin});
    margin: $box-margin;
    background: {
        image: url($bg-1);
        position: center left 45%;
        size: cover;
    }
    overflow: hidden;
    box-sizing: border-box;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color-black, .2);
        box-shadow: inset 0 100px 120px rgba($color-black, .4);
    }
}

.parallax-banner__item {
    display: none;

    @include min-screen($md) {
        display: block;
    };
}

.parallax-banner__item--slug {
    display: none;

    @include min-screen($xl) {
        display: block;
    }
}
