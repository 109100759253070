// ------------------------------------------
//  Utilities styles
// ------------------------------------------

.sm--is-visible {
    display: none;

    @include min-screen($sm) {
        display: inline-block;
    }

    @include min-screen($md) {
        display: none;
    }
}

.xxl--is-visible {
    @include min-screen($xxl) {
        display: inline-block;
    }
}

.box--top-space {
    margin-top: $box-margin;

    @include min-screen($md) {
        margin-top: 0;
    }

    @include min-screen($lg) {
        margin-top: $box-margin;
    }
}

.sm--box--top-space {
    margin-top: $box-margin;

    @include min-screen($sm) {
        margin-top: 0;
    }
}

.lg--box--top-space {
    margin-top: $box-margin;

    @include min-screen($lg) {
        margin-top: 0;
    }
}

.lg--grid-rev {
    @include min-screen($lg) {
        direction: rtl;
    }
}
