// ------------------------------------------
//  Grid
// ------------------------------------------
$gutter: 20px;
$push: true;
$pull: true;


// ------------------------------------------
//  Breakpoints
// ------------------------------------------
$xs: 300px;
$sm: 610px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1600px;

$breakpoints: (
    'xs' '(min-width: #{$xs})',
    'sm' '(min-width: #{$sm})',
    'md' '(min-width: #{$md})',
    'lg' '(min-width: #{$lg})',
    'xl' '(min-width: #{$xl})',
    'xxl' '(min-width: #{$xxl})'
);

$breakpoint-has-widths: ('xs', 'sm', 'md', 'lg', 'xl', 'xxl');
$breakpoint-has-push: ('xs', 'sm', 'md', 'lg', 'xl', 'xxl');
$breakpoint-has-pull: ('xs', 'sm', 'md', 'lg', 'xl', 'xxl');


// ------------------------------------------
//  Paths
// ------------------------------------------
$path-img: '../img/';
$path-font: '../fonts/';
