// ------------------------------------------
//  Forms
// ------------------------------------------

.input--normal, textarea {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    transition: all $animation-time ease-in-out;
    border: 1px solid rgba($color-white, .5);
    border-radius: 0;
    outline: none;
    box-sizing: border-box;
    background-color: $color-transparent;
    color: $color-white;

    &:focus {
        background-color: rgba($color-white, .1);
    }
}

textarea {
    resize: none;
}

.form--normal {
    margin: 20px auto;

    @include min-screen($md) {
        max-width: 400px;
        padding: 0 20px;
    }
}

.form__capcha {
    margin-bottom: -5px;
    font-weight: 600;
    text-align: left;
}

.form__btn {
    margin: 30px;
}



.table--normal {
    width: 100%;
    max-width: 340px;
    margin: 20px auto 40px;
    font-weight: 600;
    text-align: center;

    @include min-screen($sm) {
        max-width: 300px;
    }

    td {
        width: 50%;
        padding: 5px 10px;
    }

    th {
        padding: 10px;
    }

    tr {
        &:nth-child(even) {
            background-color: rgba($color-black, .4);
        }

        + tr {
            border-top: 1px solid rgba($color-white, .3);
        }
    }
}
