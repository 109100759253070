// ------------------------------------------
//  Headlines
// ------------------------------------------

.headline--normal {
    @extend %font--1;
    margin-top: 0;
    padding-top: 0;
    font-size: 26px;
    letter-spacing: .02em;

    @include min-screen($md) {
        font-size: 30px;
        letter-spacing: .03em;
    }

    @include min-screen($lg) {
        font-size: 38px;
    }

    @include min-screen($xxl) {
        font-size: 44px;
    }
}

.headline--big {
    font-size: 28px;

    @include min-screen($md) {
        font-size: 36px;
    }

    @include min-screen($lg) {
        font-size: 44px;
    }

    @include min-screen($xxl) {
        font-size: 52px;
    }
}

.form--error {
    margin: 20px 0;
    font-weight: 700;
}

.form--success {
    max-width: 400px;
    margin: auto;
    font-weight: 700;
}
