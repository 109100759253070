// ------------------------------------------
//  Boxes
// ------------------------------------------
.box {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all $animation-time ease-in-out;
    }

    &:hover {
        @include min-screen($lg) {
            .box__slug {
                transform: translate(-50%, -50%) scale(1);
                opacity: 1;
            }
        }
    }
}

.box--hover {
    @include min-screen($lg) {
        &:hover::after {
            background-color: rgba($color-black, .5);
        }
    }
}

.box--zoom {
    @include min-screen($lg) {
        &:hover {
            .box__img {
                transform: scale(1.05);
                filter: blur(3px);
            }
        }
    }
}

.box__img {
    width: 100%;
    padding-bottom: 100%;
    background-position: center;
    background-size: cover;

    .box--zoom & {
        transition: all $animation-time ease-in-out;
    }
}

.box__img--parallax {
    background-size: auto 105%;
}

.box__slug {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%) scale(.7);
    transition: opacity $animation-time ease-in-out, transform $animation-time ease-in-out;
    color: $color-white;
    font-weight: 600;
    text-align: center;
    opacity: 0;
    z-index: 2;

    @include min-screen($lg) {
        display: block;
    }
}

.box__btn {
    margin-top: 40px;
}

.box-content {
    max-width: 600px;
    margin: 30px auto 50px;
    text-align: center;

    @include min-screen($sm) {
        margin: 60px auto 80px;
        padding: 0 $box-margin;
    }

    @include min-screen($lg) {
        margin: 40px auto 60px;
    }
}

.box-content--full-space {
    margin: 50px auto;

    @include min-screen($sm) {
        margin: 80px auto;
    }

    @include min-screen($lg) {
        margin: 60px auto;
    }
}

.box--animation {
    transform: scale(.9);
    transition: all 1s ease-in-out;
    opacity: 0;

    &.is-visible {
        transform: scale(1);
        opacity: 1;
    }

    &.box--delay-1 {
        transition-delay: .25s;
    }

    &.box--delay-2 {
        transition-delay: .7s;
    }
}

.touch--box--is-active {
    @include max-screen($lg - 1) {
        .box__slug {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
        }

        &.box--hover {
            &::after {
                background-color: rgba($color-black, .6);
            }
        }

        &.box--zoom {
            .box__img {
                transform: scale(1.07);
                filter: blur(3px);
            }
        }
    }
}

.box--special-mobile {
    padding-bottom: 90%;

    .box__img {
        padding-bottom: 90%;
    }
}
