// ------------------------------------------
//  Global styles
// ------------------------------------------
body {
    @extend %font--2;
    transition: opacity $animation-time ease-in-out;
    font-size: 16px;
    opacity: 0;
    -webkit-font-smoothing: antialiased;

    @include min-screen($md) {
        font-size: 17px;
    }

    @include min-screen($lg) {
        font-size: 18px;
    }

    &::before {
        content: '';
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        height: $box-margin;
        background: $color-white;
        z-index: 20;
    }

    &.is-visible {
        opacity: 1;
    }
}

a {
    outline: 0;
}
